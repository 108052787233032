import React, {useMemo} from 'react'
// @ts-ignore
import {Select} from '../../../components/Select'
// @ts-ignore
import {useDebouncedState} from '../../../utils/hooks'
import {useQueryResourceSearch} from '../../../queries/rest'
import {connect} from 'react-redux'
import {ResourceFilterProps} from './types'

const ResourceFilter: React.FC<ResourceFilterProps> = ({
    filterName,
    filterConfig,
    value,
    setFieldValue,
    selectedEntityID,
    labelAccessorFunction
}) => {
    const [searchQuery, setSearchQuery] = useDebouncedState('')
    const {data, isFetching} = useQueryResourceSearch(
        filterConfig.resource,
        searchQuery,
        {entity_id: selectedEntityID}
    )

    const options = useMemo(() => {
        const _data = data as any[]
        return _data?.map(item => ({
            label:  labelAccessorFunction? labelAccessorFunction(item) : item[filterConfig.labelAccessor],
            value: item[filterConfig.valueAccessor]
        }))
    }, [data, filterConfig, labelAccessorFunction])

    return <Select
        label={filterConfig.label}
        value={value}
        options={options}
        onChange={(option: any) => setFieldValue(filterName, option)}
        onInputChange={setSearchQuery}
        loading={isFetching}
        name={filterName}
        isMulti={filterConfig.isMulti}
        isClearable={filterConfig.isClearable}
        fullWidth
    />
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps)(ResourceFilter)
