import React, {useEffect, useRef} from 'react'

// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {useInfiniteQuery} from '@tanstack/react-query'
import _ from 'lodash'
import {APIListingResponse} from '../../types/api'

// @ts-ignore
import {Loader} from '../Loader'
import StackSectionItem from './StackSectionItem'
import {StackSectionConfig} from './types'
import {connect} from 'react-redux'
import {useQueryParams} from 'use-query-params'

import './StackDatatable.scss'

interface StackSectionProps {
    sectionConfig: StackSectionConfig,
    selectedEntityID?: string
}

const StackSection: React.FC<StackSectionProps> = ({sectionConfig, selectedEntityID}) => {
    const {resource, filterConfig, sectionTitle, actions, resourceFilters, useQueryOverrides} = sectionConfig

    const [query] = useQueryParams(filterConfig.mapping)

    const {data, isFetching, fetchNextPage, hasNextPage} = useInfiniteQuery<APIListingResponse>({
        queryKey: [resource, resourceFilters, query, useQueryOverrides?.queryKey],
        queryFn: ({pageParam}) => {
            return performRequest(resource.list({
                ...resourceFilters,
                ...filterConfig.getAPIFilters(query),
                entity_id: selectedEntityID,
                page: pageParam
            }))
        },
        getNextPageParam: (response) => response?.pages?.next,
        initialPageParam: 1,
        ..._.omit(useQueryOverrides, [
            'queryKey',
            'queryFn',
            'getNextPageParam',
            'initialPageParam'
        ])
    })

    const loaderRef = useRef(null)
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0]
            if (target.isIntersecting && hasNextPage) {
                fetchNextPage()
            }
        })

        if (loaderRef.current) {
            observer.observe(loaderRef.current)
        }

        return () => {
            if (loaderRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(loaderRef.current)
            }
        }
    }, [fetchNextPage, hasNextPage])

    return <div className="stack-section-container">
        <div className="section-title-container">
            {sectionTitle && <p className="section-title">{sectionTitle}</p>}
            {actions && actions}
        </div>
        <div className='stack-items-list-container'>
            {data?.pages ? (
                <ul className="space-y-6">
                    {_.flatMap(data?.pages, (page: any) => page.data.results).map((item) => (
                        <StackSectionItem
                            key={item.id}
                            item={item}
                            render={sectionConfig.render}
                        />
                    ))}
                    <div ref={loaderRef}>
                        {isFetching && (
                            <div className="center-loader">
                                <Loader/>
                            </div>
                        )}
                    </div>
                </ul>
            ) : !isFetching && (
                <p>Nu există date</p>
            )}
        </div>
    </div>
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps, undefined)(StackSection)
